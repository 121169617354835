import { render, staticRenderFns } from "./ShoppingCart.vue?vue&type=template&id=68bd2831&"
import script from "./ShoppingCart.vue?vue&type=script&lang=js&"
export * from "./ShoppingCart.vue?vue&type=script&lang=js&"
import style0 from "./ShoppingCart.vue?vue&type=style&index=0&id=68bd2831&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DynamicButton: require('/codebuild/output/src3114493269/src/and-dine/components/DynamicButton.vue').default,DatePicker: require('/codebuild/output/src3114493269/src/and-dine/components/DatePicker.vue').default,TimePicker: require('/codebuild/output/src3114493269/src/and-dine/components/TimePicker.vue').default,QuantityOperator: require('/codebuild/output/src3114493269/src/and-dine/components/QuantityOperator.vue').default,LoadingBar: require('/codebuild/output/src3114493269/src/and-dine/components/LoadingBar.vue').default})
